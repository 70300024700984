import React from 'react';
import FAQItem from "../components/questions/Questions";
import styles from "./FifthScreen.module.css";

const FifthScreen = () => {
  return (
    <div className="w-full max-w-4xl mx-auto p-4">
      <div className={styles.title}>שאלות נפוצות</div>
      
      <FAQItem 
        question="שמחה, מה קורה יום אחרי הקורס?" 
        answer="אחרי הקורס את מקבלת מענה לכל שאלה והתייעצות. את מספקת את התשובות שאת בעצמך חיפשת בתחילת הדרך שלך, ואני כאן ללוות אותך בכל צעד בדרך."
      />
      
      <FAQItem 
        question="התאכזבתי מקורס איפור בעבר - איך אני יודעת שאצלך זה יהיה אחרת" 
        answer="קודם כל, העובדה שאת עדיין כאן ומעוניינת בתחום מראה כמה התשוקה שלך חזקה. אני מבינה את החשש שלך, ודווקא בגלל זה אני מתחייבת להעניק לך חוויה שונה לגמרי - עם ליווי אישי, תמיכה מתמשכת, והכשרה מקצועית ברמה הגבוהה ביותר."
      />
      
      <FAQItem 
        question="אני מאוהבת בתחום אבל חוששת שלא אצליח להרוויח ממנו מספיק" 
        answer="זה חשש טבעי ומובן. חשוב לדעת שתחום האיפור מציע הזדמנויות רבות להכנסה - החל מאיפור כלות, דרך הדרכות ועד לעבודה עם מותגים. בקורס, מעבר ללימוד הטכניקות, אני מלמדת גם כיצד לבנות עסק מצליח ולמצוא את הנישה המתאימה לך."
      />
      
      <FAQItem 
        question="תוך כמה זמן אני יכולה להצליח בתחום?" 
        answer="ההצלחה בתחום תלויה במספר גורמים - ההשקעה שלך, היכולת שלך ליישם את הנלמד, והאסטרטגיה העסקית שתבחרי. עם זאת, עם התמיכה והכלים שתקבלי בקורס, תוכלי להתחיל לעבוד באופן מקצועי כבר מסיום הקורס ולבנות את המוניטין שלך בהדרגה."
      />
    </div>
  );
};

export default FifthScreen;